exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplikacje-mobilne-tsx": () => import("./../../../src/pages/aplikacje-mobilne.tsx" /* webpackChunkName: "component---src-pages-aplikacje-mobilne-tsx" */),
  "component---src-pages-applications-software-tsx": () => import("./../../../src/pages/applications-software.tsx" /* webpackChunkName: "component---src-pages-applications-software-tsx" */),
  "component---src-pages-audyty-kri-ksc-tsx": () => import("./../../../src/pages/audyty-kri-ksc.tsx" /* webpackChunkName: "component---src-pages-audyty-kri-ksc-tsx" */),
  "component---src-pages-cyberbezpieczenstwo-tsx": () => import("./../../../src/pages/cyberbezpieczenstwo.tsx" /* webpackChunkName: "component---src-pages-cyberbezpieczenstwo-tsx" */),
  "component---src-pages-cybersecurity-tsx": () => import("./../../../src/pages/cybersecurity.tsx" /* webpackChunkName: "component---src-pages-cybersecurity-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-hosting-domains-tsx": () => import("./../../../src/pages/hosting-domains.tsx" /* webpackChunkName: "component---src-pages-hosting-domains-tsx" */),
  "component---src-pages-hosting-domeny-tsx": () => import("./../../../src/pages/hosting-domeny.tsx" /* webpackChunkName: "component---src-pages-hosting-domeny-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-iot-tsx": () => import("./../../../src/pages/iot.tsx" /* webpackChunkName: "component---src-pages-iot-tsx" */),
  "component---src-pages-mobile-apps-tsx": () => import("./../../../src/pages/mobile-apps.tsx" /* webpackChunkName: "component---src-pages-mobile-apps-tsx" */),
  "component---src-pages-panel-tsx": () => import("./../../../src/pages/panel.tsx" /* webpackChunkName: "component---src-pages-panel-tsx" */),
  "component---src-pages-partnership-tsx": () => import("./../../../src/pages/partnership.tsx" /* webpackChunkName: "component---src-pages-partnership-tsx" */),
  "component---src-pages-pomoc-tsx": () => import("./../../../src/pages/pomoc.tsx" /* webpackChunkName: "component---src-pages-pomoc-tsx" */),
  "component---src-pages-serwisy-internetowe-oprogramowanie-tsx": () => import("./../../../src/pages/serwisy-internetowe-oprogramowanie.tsx" /* webpackChunkName: "component---src-pages-serwisy-internetowe-oprogramowanie-tsx" */),
  "component---src-pages-strony-sklepy-internetowe-tsx": () => import("./../../../src/pages/strony-sklepy-internetowe.tsx" /* webpackChunkName: "component---src-pages-strony-sklepy-internetowe-tsx" */),
  "component---src-pages-websites-webstores-tsx": () => import("./../../../src/pages/websites-webstores.tsx" /* webpackChunkName: "component---src-pages-websites-webstores-tsx" */),
  "component---src-pages-wspolpraca-tsx": () => import("./../../../src/pages/wspolpraca.tsx" /* webpackChunkName: "component---src-pages-wspolpraca-tsx" */)
}

